<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!-- b-link class="brand-logo">
        <app-logo />
        <h2 class="brand-text text-primary ml-1">
          e-PLAN
        </h2>
      </b-link -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to e-PLAN! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Username"
                label-for="login-id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="login-id"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder="SISWA ID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!-- <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="SISWA Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group v-if="false">
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <b-alert
                v-if="loginError"
                variant="danger"
                show
              >
                <div class="alert-body font-small-2">
                  <p>
                    <feather-icon
                      :disabled="isLoading"
                      icon="AlertTriangleIcon"
                      size="18"
                      class="mr-50"
                    />
                    <small class="">{{ loginError }}</small>
                  </p>
                </div>
              </b-alert>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="mt-2"
                block
                :disabled="invalid || isLoading"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                  type="grow"
                />
                Sign in
              </b-button>

              <div class="mt-1 text-center">
                <b-link href="/home.html">
                  <span>&laquo; Back to home</span>
                </b-link>
              </div>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle,
  BImg, BForm, BButton, BAlert, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { get } from 'lodash'
import { nextTick } from '@vue/composition-api'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/splash.png'),
      isLoading: false,

      // validation rules
      required,
      email,
      loginError: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    hideQuickLogin() {
      return this.$route.query.uat == 0
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/splash.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    // console.log(localStorage.getItem())
    if (isUserLoggedIn()) {
      console.log('already loggedin')
      this.$router.push('/')
    }
  },
  methods: {
    autoLogin(user) {
      const credential = user.split(':')
      // eslint-disable-next-line prefer-destructuring
      this.username = credential[0]
      // eslint-disable-next-line prefer-destructuring
      this.password = credential[1]

      nextTick(() => {
        this.login()
      })
    },
    login() {
      this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            this.isLoading = true
            const payload = {
              username: this.username,
              password: this.password,
            }
            this.loginError = null

            this.$store.dispatch('auth/login', payload)
              .then(response => {
                const { user } = response.payload
                const userData = {
                  id: user.id,
                  fullName: user.name,
                  username: user.username,
                  avatar: get(user, 'profile.image_url'),
                  email: user.email,
                  role: get(response, 'payload.roles.0'),
                  plan_owner_id: user.plan_owner_id,
                  plan_owner: user.plan_owner,
                  ability: response.payload.permissions.map(p => {
                    const permission = p.split('-')
                    return { action: permission[1], subject: permission[0] }
                  }),
                  extras: {
                    eCommerceCartItemsCount: 5,
                  },
                }

                // add additional admin-access ability
                const hasAdminAbility = userData.ability.find(a => ['user', 'audit'].includes(a.subject))
                if (hasAdminAbility) {
                  userData.ability.push({ action: 'access', subject: 'admin' })
                }

                localStorage.setItem('userData', JSON.stringify(userData))
                localStorage.setItem('accessToken', response.payload.token)
                localStorage.setItem('refreshToken', response.payload.token)
                this.$ability.update(userData.ability)
                this.$router.push('/').then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'UserIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  })
                })
              })
              .catch(({ response }) => {
                console.log(response)
                this.loginError = response.data.message ?? null
              })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
